<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem v-if="type === 'detail'">订单查看</BreadcrumbItem>
        <BreadcrumbItem v-if="type === 'examine'">订单审核</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="card__container">
      <div class="container__title">基本信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单号">
              {{ orderInfo.orderSn }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="客户">
              {{ orderInfo.orderCorporationName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="商家">
              {{ orderInfo.producerCorporationName }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单类别">
              {{ orderInfo.orderTypeValue }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="申请时间">
              {{ orderInfo.createTime ? tsFormat(orderInfo.createTime) : "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item
              label="订单状态"
              v-if="
                fromRouterName === 'FactoryReturnedOrderList' ||
                  storeRouterName === 'FactoryReturnedOrderList'
              "
            >
              <span>{{ orderInfo.nodeStatus }}</span>
            </a-form-item>
            <a-form-item label="审核状态" v-else>
              <span
                class="status--orange"
                v-if="orderInfo.orderStatusValue === '待审核'"
              >
                {{ orderInfo.orderStatusValue }}
              </span>
              <span v-else>{{ orderInfo.orderStatusValue }}</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="原订单号">
              <a>
                <span @click="onCheckPurchaseOrder(orderInfo.orderPurchaseSn)">
                  {{ orderInfo.orderPurchaseSn }}
                </span>
              </a>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container" v-if="type === 'examine'">
      <div class="container__title">收货信息</div>
      <div class="address__container">
        <span>{{ senderAddress }}</span>
        <a-icon
          type="edit"
          :style="{ fontSize: '16px' }"
          @click="onEditMineDeliverInfo"
        />
      </div>
    </section>
    <section
      class="card__container"
      v-if="type === 'detail' && orderInfo.orderStatusValue !== '待审核'"
    >
      <div class="container__title">收货信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="收货人姓名">
              {{ orderInfo.consigneeRealName || "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货人电话">
              {{ orderInfo.consigneeTel || "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货地址">
              {{ orderInfo.consigneeAddress || "-" }}
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">商品信息</div>
      <a-table
        class="commodity__table"
        :data-source="commodityData"
        :columns="commodityColumns"
        :loading="loading"
        :pagination="false"
        :rowKey="(record, index) => index"
      ></a-table>
    </section>
    <section class="card__container">
      <div class="container__title">申请信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="18">
            <a-form-item label="备注">
              {{ orderInfo.orderMemo }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="照片">
              <div class="pic__container" v-viewer>
                <div v-for="(item, index) in orderInfo.files" :key="index">
                  <img
                    :data-source="imageBaseUrl + item.fileUrl"
                    :src="imageBaseUrl + item.fileUrl"
                    :alt="item.fileName"
                  />
                </div>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">退款金额</div>
      <a-table
        class="flow__table"
        :columns="flowColumns"
        :data-source="flowList"
        :loading="flowLoading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="huifuSeqId" slot-scope="text">
          {{ text || "-" }}
        </template>
        <template slot="paymentTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
      </a-table>
    </section>
    <section
      class="card__container"
      :class="type === 'examine' ? 'high__bottom' : 'low__bottom'"
    >
      <div class="container__title between">
        流程日志
        <a @click="onShowProcessImg" class="process__img">点击查看流程图</a>
        <div v-viewer class="images">
          <img
            src="../../../assets/imgs/process/returnedOrder.png"
            :data-source="
              require('../../../assets/imgs/process/returnedOrder.png')
            "
            alt="流程图"
          />
        </div>
      </div>
      <a-table
        class="log__table"
        :columns="logColumns"
        :data-source="logData"
        :loading="logLoading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="createTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
        <template slot="reason" slot-scope="text">
          {{ text ? text : "-" }}
        </template>
      </a-table>
    </section>
    <section class="examine__container" v-if="type === 'examine'">
      <a-form class="examine__form">
        <a-row>
          <a-col :span="24">
            <a-form-item label="订单审核意见">
              <a-textarea
                :auto-size="{ minRows: 1, maxRows: 2 }"
                v-model="approvalMemo"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div class="tip">
        <a-icon type="info-circle" />
        审核不通过，审核意见必填
      </div>
      <div class="operation">
        <a-space>
          <a-button @click="onReject">不通过</a-button>
          <a-button type="primary" @click="onPass" :loading="passLoading">
            通过
          </a-button>
        </a-space>
      </div>
    </section>

    <!-- 发货地址选择弹窗 -->
    <a-modal
      title="请选择合适的地址"
      :visible="sendVisible"
      @cancel="sendVisible = false"
    >
      <div v-if="addressList.length > 0">
        <a-radio-group v-model="defaultSelectAddressId">
          <a-radio
            v-for="item in addressList"
            :key="item.addressId"
            :value="item.addressId"
            class="radio__item"
          >
            {{ item.addressItem }}
          </a-radio>
        </a-radio-group>
      </div>
      <a-empty v-else description="暂无地址，请去新增" />
      <template slot="footer">
        <a-button @click="onEnterAddress">
          地址库管理
        </a-button>
        <a-button type="primary" @click="onSureAddress">
          确定
        </a-button>
      </template>
    </a-modal>
  </a-layout>
</template>

<script>
import BreadcrumbItem from "@/components/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb";
import {
  fetchOrderDetail,
  fetchOrderLog,
  fetchPayResult,
  orderExamine
} from "@/services/OrderManageService";
import { tsFormat } from "@/components/DateUtils";
import { fetchAddressList } from "@/services/OrderService";
import { imageBaseUrl } from "@/services/HttpService";

const commodityColumns = [
  {
    title: "商品编号",
    dataIndex: "skuCode",
    width: "20%"
  },
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "35%"
  },
  {
    title: "出厂单价（元）",
    dataIndex: "skuFactoryPrice",
    width: "15%"
  },
  {
    title: "退货数量",
    dataIndex: "purchaseQuantity",
    width: "15%"
  },
  {
    title: "出厂总价（元）",
    dataIndex: "skuFactoryTotalPrice",
    width: "15%"
  }
];

const logColumns = [
  {
    title: "执行人",
    dataIndex: "realName",
    width: "20%"
  },
  {
    title: "所属渠道类型",
    dataIndex: "distributorType",
    width: "15%"
  },
  {
    title: "操作时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "20%"
  },
  {
    title: "执行动作",
    dataIndex: "operation",
    width: "15%"
  },
  {
    title: "审核意见",
    dataIndex: "reason",
    scopedSlots: { customRender: "reason" },
    width: "30%"
  }
];
const flowColumns = [
  {
    title: "流水号",
    dataIndex: "huifuSeqId",
    scopedSlots: { customRender: "huifuSeqId" },
    width: "15%"
  },
  {
    title: "付款机构",
    dataIndex: "paymentCorporationName",
    width: "20%"
  },
  {
    title: "收款机构",
    dataIndex: "receiveCorporationName",
    width: "20%"
  },
  {
    title: "应付款金额（元）",
    dataIndex: "actualPayment",
    width: "10%"
  },
  // {
  //   title: "实际付款金额（元）",
  //   dataIndex: "accountPaid",
  //   width: "10%"
  // },
  {
    title: "付款时间",
    dataIndex: "paymentTime",
    scopedSlots: { customRender: "paymentTime" },
    width: "15%"
  },
  {
    title: "状态",
    dataIndex: "paymentStatus",
    width: "10%"
  }
];

export default {
  name: "FactoryReturnedOrderDetail",
  components: { Breadcrumb, BreadcrumbItem },
  data() {
    return {
      orderSn: this.$route.params.orderSn,
      type: this.$route.params.type,
      commodityData: [],
      commodityColumns,
      loading: false,
      logColumns,
      logData: [],
      logLoading: false,
      orderInfo: {},
      tsFormat,
      approvalMemo: "",
      senderAddress: "",
      defaultSelectAddressId: "",
      addressList: [],
      sendVisible: false,
      imageBaseUrl,
      passLoading: false,
      fromRouterName: "",
      storeRouterName: "",
      flowColumns,
      flowList: [],
      flowLoading: false
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromRouterName = from.name;
      if (vm.fromRouterName) {
        sessionStorage.setItem("fromRouterName", vm.fromRouterName);
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem("fromRouterName");
    next();
  },
  mounted() {
    if (sessionStorage.getItem("fromRouterName")) {
      this.storeRouterName = sessionStorage.getItem("fromRouterName");
    }
    this.loadDetail();
    this.loadLogList();
    this.onLoadAddressList();
    this.loadPayBill();
  },
  methods: {
    // 查看流程图
    onShowProcessImg() {
      const viewer = this.$el.querySelector(".images").$viewer;
      viewer.show();
    },
    // 加载详情
    loadDetail() {
      this.loading = true;
      const params = {
        orderSn: this.orderSn,
        type: "approval"
      };
      fetchOrderDetail(params).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          this.orderInfo = resp.data.data;
          this.commodityData = this.orderInfo.instantOrderSkus;
          if (this.commodityData && this.commodityData.length > 0) {
            let num = 0;
            let factoryPrice = 0;
            this.commodityData.forEach(item => {
              num = num + parseInt(item.purchaseQuantity);
              factoryPrice =
                factoryPrice + parseFloat(item.skuFactoryTotalPrice);
            });
            const params = {
              skuCode: "合计",
              purchaseQuantity: num,
              skuFactoryTotalPrice: factoryPrice.toFixed(2)
            };
            this.commodityData.push(params);
          }
        }
      });
    },
    // 查看采购订单
    onCheckPurchaseOrder(orderSn) {
      const str = orderSn.substring(0, 2);
      if (str === "YQ") {
        const routeData = this.$router.resolve({
          name: "ForwardPurchaseSubOrder",
          params: { orderSn: orderSn, status: "payment-approval" }
        });
        window.open(routeData.href, "_blank");
      } else {
        const routeData = this.$router.resolve({
          name: "PurchaseOrderDetailFactory",
          params: { orderSn: orderSn, type: "detail" }
        });
        window.open(routeData.href, "_blank");
      }
    },
    // 加载流程日志
    loadLogList() {
      this.logLoading = true;
      fetchOrderLog(this.orderSn, "producer").then(resp => {
        this.logLoading = false;
        if (resp.data.code === "SUCCESS") {
          this.logData = resp.data.data;
        }
      });
    },
    // 不通过
    onReject() {
      if (!this.approvalMemo) {
        this.$message.info("请输入订单审核意见");
        return;
      }
      const params = {
        orderSn: this.orderSn,
        isAdopt: false,
        approvalMemo: this.approvalMemo
      };
      orderExamine(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("提交成功");
          this.$router.go(-1);
        }
      });
    },
    // 通过
    onPass() {
      if (!this.defaultSelectAddressId) {
        this.$message.info("请选择收货信息");
        return;
      }
      const addressItem = this.addressList.find(
        item => item.addressId === this.defaultSelectAddressId
      );
      this.passLoading = true;
      const params = {
        orderSn: this.orderSn,
        isAdopt: true,
        approvalMemo: this.approvalMemo,
        consigneeName: addressItem.senderName,
        consigneeTel: addressItem.senderTel,
        consigneeProvinceCode: addressItem.provinceCode,
        consigneeCityCode: addressItem.cityCode,
        consigneeCountyCode: addressItem.countyCode,
        consigneeDetailAddress: addressItem.detailAddress
      };
      orderExamine(params).then(resp => {
        this.passLoading = false;
        if (resp.data.code === "SUCCESS") {
          this.$message.success("提交成功");
          this.$router.go(-1);
        }
      });
    },
    // 发货弹窗展示
    onEditMineDeliverInfo() {
      this.sendVisible = true;
    },
    // 获取当前原厂的发货地址列表
    onLoadAddressList() {
      const factoryId = localStorage.getItem("factoryId");
      fetchAddressList(factoryId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (data.length > 0) {
            data.forEach(item => {
              if (
                item.senderProvinceName ||
                item.senderCityName ||
                item.senderCountyName
              )
                if (item.senderProvinceName === item.senderCityName) {
                  item.area = `${item.senderCityName} ${item.senderCountyName} ${item.detailAddress}`;
                } else {
                  item.area = `${item.senderProvinceName} ${item.senderCityName} ${item.senderCountyName} ${item.detailAddress}`;
                }
              item.addressItem = `${item.area}，${item.senderName}，${item.senderTel}`;
            });
          }
          this.addressList = data;
          if (this.addressList.length > 0) {
            this.defaultSelectAddressId = this.addressList[0].addressId;
            this.senderAddress = this.addressList[0].addressItem;
          }
        }
      });
    },
    // 进入发货信息编辑页面
    onEnterAddress() {
      this.$router.push({
        name: "ShippingAddress",
        params: { procureOrderSn: this.orderSn }
      });
      this.sendVisible = false;
    },
    // 选择发货地址信息
    onSureAddress() {
      if (this.defaultSelectAddressId === "") {
        this.$message.info("请选择一个发货地址");
      } else {
        const index = this.addressList.findIndex(
          item => item.addressId === this.defaultSelectAddressId
        );
        this.senderAddress = this.addressList[index].addressItem;
        this.sendVisible = false;
      }
    },
    // 查付款单
    loadPayBill() {
      this.flowLoading = true;
      fetchPayResult(this.orderSn)
        .then(resp => {
          this.flowLoading = false;
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            if (data && data.length > 0) {
              this.flowList = data;
            }
          }
        })
        .catch(() => {
          this.flowLoading = false;
        });
    }
  }
};
</script>

<style scoped>
.container__title {
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  font-weight: bold;
}

/deep/ .ant-form-item {
  margin: 10px 40px 0 0;
}

/deep/ .ant-form-item .ant-form-item-label {
  min-width: 85px !important;
  text-align: left !important;
}

/deep/ .ant-form-item-label > label {
  color: rgba(102, 102, 102, 0.8);
  font-size: 14px;
}

.tip {
  display: flex;
  align-items: center;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}

.tip /deep/ .anticon {
  margin-right: 6px;
}

.top {
  margin-top: 10px;
}

.price__item {
  width: 70%;
  min-width: 1080px;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid rgba(164, 173, 179, 0.6);
  box-sizing: border-box;
  padding: 13px 10px;
  margin-top: 20px;
}

.price__item--value {
  flex: 1;
  display: flex;
  margin: 0 10%;
}

.label {
  min-width: 100px;
}

.right {
  margin-right: 26%;
}

.left {
  margin-left: 26%;
}

.commodity__table {
  margin-top: 20px;
}

.between {
  display: flex;
  justify-content: space-between;
}

.images img {
  display: none;
}

.process__img {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  font-weight: unset;
}

.high__bottom {
  margin-bottom: 150px;
}

.low__bottom {
  margin-bottom: 30px;
}

.examine__container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px 20px 20px 220px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.19);
}

.examine__form /deep/ .ant-form-item {
  display: flex;
  margin: 0 40px 0 0;
}

.tip /deep/ .anticon {
  margin-right: 6px;
}

.top {
  margin-top: 10px;
}

.operation {
  display: flex;
  justify-content: flex-end;
}

.log__table {
  margin-top: 20px;
}

.status--orange {
  color: rgba(255, 134, 0, 1);
}

.radio__item {
  margin-bottom: 10px;
}

.address__container {
  margin-top: 20px;
}

.pic__container {
  display: flex;
}

.pic__container img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
}

.flow__table {
  margin-top: 20px;
}
</style>
